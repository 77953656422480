<template> 
   <CRow>
     <CCol md="12" >
        <div class="card mb-2">
          <CCardHeader><CIcon name="cil-notes" height="20" /><span class="h6"> 好店緣報告使用概況 License Seats Summary</span></CCardHeader>
            <div class="card-body">
                <CRow class="text-center">  
                    <CCol><div class="info"><strong class="h2">{{totalSeats}}</strong><br><small class="text-muted">總購買量</small></div></CCol>
                    <CCol><div class="info"><strong class="h2 text-danger">{{usedlSeats}}</strong><br><small class="text-muted">已使用</small></div></CCol>
                    <CCol><div class="info"><strong class="h2 text-success">{{openSeats}}</strong><br><small class="text-muted">未使用</small></div></CCol>
                    <CCol>
                        <div class="info"> 
                           <strong><CButton class="btn-circular" color="success"  @click='PurchaseModal=true'><CIcon name="cil-plus" customClasses="custom-icon-w25p"></CIcon></CButton></strong>
                           <br/> <small class="text-muted">加購</small>
                        </div>
                    </CCol>
                </CRow>
            </div>
        </div>  
        <div class="card" >
          <CCardHeader><CIcon name="cil-list" height="20" /><span class="h6"> 消費記錄 Billing History</span></CCardHeader>
            <div class="card-body" style="padding: 5px;"> 
               <div class="sm-table-container">
                    <vueTable2 :apiUrl="billingApiUrl" :fieldsDef="billingfieldsDef" :detailRowComponent="billingDetailRowComponent"> 
                    </vueTable2>  
               </div>
            </div>
        </div> 
     </CCol> 
     <CModal title="購買資訊" :show.sync="PurchaseModal" size="lg" id="purchaseDiv"> 
         <CRow>
             <CCol class="col-sm-4" v-for="(ProductInfo,index) in Products" :key="'pd'+ProductInfo.productPriceId">
                 <CCard class="text-center" :style="[productPicked == index ? {'background': '#EEE'} : {'background': '#FFF'}]">
                     <CCardBody @click="productPicked=index">
                         <!--<span :class="'xcc xc'+index"><span v-if="index!==0">省下{{(Products[0].Price*ProductInfo.ReportCount)-ProductInfo.Price}}元</span></span>-->
                         <div style="padding:0.6rem 0rem 0.6rem 0rem">
                             <input type="radio" :value="index" class="text-value-xl" v-model="productPicked">
                         </div>
                         <div style="padding:0.6rem 0rem 0.6rem 0rem">
                             <span class="h4 ">{{ProductInfo.localName}}</span>
                         </div>
                         <div style="padding:2rem 0rem 2rem 0rem">
                             <span class="text-value-xl" :style="'color:'+color[index]">${{ProductInfo.Price}} </span> <small>{{ProductInfo.Currency}}</small><br />
                             <div class="text-dark">
                                 <span v-if="index!=0"><del>{{'$'+Products[0].Price*ProductInfo.ReportCount}}</del></span>
                                 <span v-else style="display:inline-block;">&nbsp;</span>
                             </div>
                         </div>
                         <div style="padding:0.6rem 0rem 0.6rem 0rem">
                             <vue-numeric-input v-model="ProductInfo.Qty" :min="1" :max="30" :step="1"></vue-numeric-input>
                         </div>

                     </CCardBody>
                 </CCard>
             </CCol>
         </CRow>
         <CRow>
             <CCol>

             </CCol>
         </CRow>
          <template v-slot:header>
          <h5 class="modal-title"> 購買資訊 </h5><button type="button" aria-label="Close" class="close" @click="closeModal">×</button> 
           </template>
         <template v-slot:footer>
             <!-- <button type="button" class="btn btn-secondary"> 取消 </button> -->
             <CRow>
                 <CCol>
                     <div class="text-center"> 
                         <strong><p class="h5  text-danger  ">合計:${{Products[productPicked].Qty*Products[productPicked].Price}}</p> </strong>
                         <button style="width: 8rem;" type="button" class="btn btn-success text-white  " @click='addToCar'> 前往結帳 </button>
                     </div>
                 </CCol>
             </CRow>
         </template>
     </CModal>　 
 </CRow> 
</template>

<script>
 import Vue from "vue";
 
 import vueTable2 from "../../components/DisplayTemplate/vueTable2.vue"
 import CryptoJS from '../../util/CryptoJS';
 import VueNumericInput from 'vue-numeric-input';
 import axios from 'axios';
 import store from '../../util/store';
 import appSettings from '../../util/settings';
 
 import BillingDetailRow from "../../views/account/MyAccount_BillingDetail.vue";
 Vue.component("billing-detail-row", BillingDetailRow);

let color=["#000","#8CBE02","#F0A21A"];
let pageSize = appSettings.PerPage;
let fields = [
    {
      name: 'orderDate',
      title: '日期',
      //width: "10%",  
    },
    {
      name: 'orderrNumber',
      title: '訂單編號',
     // width: "20%",
    },
    {
      name: 'orderStatusLocal',
      title: '狀態',
      width: "15%", 
      formatter: (value) => { 
          return (value === null) ? '' :
                 (value.includes("完成")) ? '<span class="badge badge-success"><span class="text-white">' + value + '</span></span>':
                                            '<span class="badge badge-warning"><span class="text-white">' + value + '</span></span>'
        }
    },  
    {
      name:'orderAmount',
      title: '金額' 
    },
   /* {
          name: '__slot:actions',    
          title: 'Actions',
          titleClass: 'center aligned',
          dataClass: 'center aligned'
    }*/
];

    export default {
        name: 'myAccount',
          components: {  
            
            VueNumericInput,
            vueTable2
        }, 
        data() {
            return {
                //Qty: 1,
                PurchaseModal:false,
                productPicked:1,
                Products:[],
                /*ProductInfo: {
                              name:null,
                              localName:null,
                              productPriceId:null,
                              Price:null,
                              Currency:null
                },*/       
                totalSeats:0,
                usedlSeats:0,
                openSeats:0,  

                pageSize:pageSize,
                color:color,
                billingApiUrl: appSettings.GetBilling +"?userToken=" + encodeURIComponent(store.state.token) + "&perPage=" +pageSize, 
                billingfieldsDef: fields,
                billingDetailRowComponent:"billing-detail-row"
            }
        }, 
        methods: { 
          addToCar(){
            var prodsArray = []; 
            let ProductInfo =this.Products[this.productPicked];
            for (var i = 0; i < ProductInfo.Qty; i++) { 
                prodsArray = prodsArray.concat(ProductInfo.productPriceId);
            };
            var token = store.state.token;
            var key = token.substr(0, 16);
            var iv = token.substr(16, 16);

            //傳多個產品價格ID,重覆代表買多個(用,分隔)加密 
            var encryptProds =CryptoJS.encrypt(prodsArray.join(","),key,iv); 
            let url = appSettings.AddToCar + "?Prods=" +encodeURIComponent(encryptProds)+"&userToken=" + encodeURIComponent(store.state.token);
            
            //window.location = encodeURI(url); 
            window.location = url;
          },
          getProducts: function() {  
               var self = this;
               axios.post(appSettings.GetProducts)
                    .then(function(response){ 
                       var data = response.data;  
                       if(data!==undefined && data.length>0){
                         data.forEach(p => {
                           self.Products.push({ 
                                name:p.ProductName,
                                localName:p.ProductLocalizedName,
                                productPriceId:p.ProductPriceID,
                                Price:p.Price,
                                Currency:p.Currency,
                                ReportCount:p.ReportCount,
                                Qty: 1,
                           })
                         });
                       }
                      
                      /*
                       if(data[0] !== undefined){ 
                           var _productInfo =  {
                                name:data[0].ProductName,
                                localName:data[0].ProductLocalizedName,
                                productPriceId:data[0].ProductPriceID,
                                Price:data[0].Price,
                                Currency:data[0].Currency
                           };
                           self.ProductInfo =  _productInfo; 
                       } */

                       if(self.$route.params.OutSidePurchaseModal!==undefined){
                         self.PurchaseModal = self.$route.params.OutSidePurchaseModal; 
                       }
                         
                    })
                    .catch(function (error) {  
                     console.log(error);
                });
           
          },
          getLicenseSeatCount(){
               //console.log(appSettings.GetLicenseSeatCount);
                axios.post(appSettings.GetLicenseSeatCount, 
                    {
                        userToken:store.state.token, 
                    })
                    .then(this.setLicenseData) 
                    .catch(function (error) { 
                        console.log(error);  
                    }) 
                   // .finally(() => this.isLoading = false) 
          },
          setLicenseData(response){               
            var data = response.data;
                if(!data.IsLogin){                    
				    store.commit("clearUser");
				    this.$router.replace({ name: "Login" });
                }
                else{
                    var result = data.Result; 
                    this.totalSeats = result.TotalSeatCount;
                    this.usedlSeats = result.UsedSeatCount;
                    this.openSeats = result.StockSeatCoun; 
                }
               
          },
          closeModal(){
              let routerName = this.$route.params.TriggerRouterName;
              this.PurchaseModal = false;
              if(routerName !== "MyAccount" && routerName !== undefined ){
                this.$router.go(-1); 
              }
          }
        },
         created(){
           this.getProducts();
           this.getLicenseSeatCount();  
        } 
    }
</script>

 <style>
 .xcc{
    border-top-right-radius: 0.2rem;
    position: absolute;
    padding: 0.5rem;
    color: #000; 
    top: -0.1rem;
    right: 0rem;
 }

 .xc1{ 
    background-color: #BFD780; 
 }

 .xc2{ 
    background-color:#F9CA5C;  
 }
  
 .vuetable-td-orderStatusLocal .badge {
    font-size: 100%;
 }
 </style>
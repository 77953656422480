<template>   
   <div> 
  <CCard>
     <CCardHeader>
       訂單編號: <span class="badge badge-dark">{{rowData.orderrNumber}}</span>
    </CCardHeader>
    <CCardBody>
      <CTabs>
        <CTab active title="付款資訊">  
          <div>
            <table class="table table-clear ">
              <tbody>
                <tr>
                  <td class="left"><strong>產品項目</strong></td>
                  <td class="">{{rowData.orderItems[0].ProductName}} <small class="text-danger">* {{rowData.orderItems[0].Qty}}</small> </td>
                </tr>
                <tr>
                   <td class="left"><strong>金  額</strong></td>
                   <td class="">${{rowData.orderAmount}}</td>
                </tr>
                <tr>
                  <td class="left"><strong>付款方式</strong></td>
                  <td class="">{{rowData.paymentTypeLocal}}
                        <div v-if="rowData.paymentType =='VACC'"> 
                             <table class="table-sm table-clear mt-2">
                               <tr>
                                 <td class="text-dark">銀行代號</td>
                                 <td><strong>{{rowData.orderPayments.ATM_BankCode}}</strong></td>
                               </tr>
                                <tr>
                                 <td class="text-dark">轉入帳號</td>
                                 <td><strong>{{rowData.orderPayments.ATM_CodeNo}}</strong></td>
                               </tr>
                                <tr>
                                 <td class="text-dark">應轉金額</td>
                                 <td class="text-danger"><strong>{{currency}} ${{rowData.orderAmount}}</strong></td>
                               </tr>
                                <tr>
                                 <td class="text-dark">繳款期限</td>
                                 <td class="text-danger"><strong>{{expireTime}}</strong></td>
                               </tr>
                             </table>  
                        </div>
                  </td>
                </tr>
                <tr>
                  <td class="left"><strong>付款時間</strong></td>
                  <td class="right">{{payTime}}</td>
                </tr> 
                <tr>
                  <td class="left"><strong>付款狀態</strong></td>
                  <td class="right"><span>{{rowData.orderStatusLocal }}
                      <CIcon  v-if="rowData.orderStatus==3"  name="cil-check-alt" height="25"   /> </span>
                  </td>
                </tr>
                <!-- ProductID為13(AdvancedReport)才顯示備註 -->
                <tr v-if="rowData.orderItems[0]['ProductID'] === 13">
                  <td class="left"><strong>備註</strong></td>
                  <td class="right" style="width: 77%; color: red;">
                    <span>{{`我們已收到您的申請，目前正在進行初步確認。若資料無誤，我們會先寄送受理通知信至您所留下的信箱，當您收到受理通知信起算10個工作日內，您將收到店週情報通報告。`}}</span>
                  </td>
                </tr>
              </tbody>
            </table> 
          </div> 
        </CTab>
        <CTab title="訂購人資訊" >
           <div class=" ">
            <table class="table table-clear">
              <tbody>
                <tr>
                  <td class="left"><strong>名稱</strong></td>
                  <td class="">{{rowData.orderContactName}}</td>
                </tr>
                <tr>
                  <td class="left"><strong>電話</strong></td>
                  <td class="">{{rowData.orderContactPhone}}</td>
                </tr>
                <tr>
                  <td class="left"><strong>發票地址</strong></td>
                  <td class="right">{{rowData.orderInvoiceAddress}}</td>
                </tr> 
                  <tr>
                  <td class="left"><strong>統一編號</strong></td>
                  <td class="right">{{rowData.orderTaxNumber}}</td>
                </tr> 
                  <tr>
                  <td class="left"><strong>抬頭</strong></td>
                  <td class="right">{{rowData.orderTaxTitle}}</td>
                </tr> 
              </tbody>
            </table> 
          </div> 
        </CTab> 
      </CTabs>
    </CCardBody>
  </CCard>  
 
</div>  

</template>

<script>

 

export default {
  props: {
    rowData: {
      type: Object,
      required: true
    },
    rowIndex: {
      type: Number
    }
  },
  data() {
    return {
      currency: (this.rowData.orderPayments==null? "":this.rowData.orderItems==null?"":this.rowData.orderItems[0].ProductPriceCurrency),
      expireTime:(this.rowData.orderPayments==null? "":this.rowData.orderPayments.ExpireDate==null?"":new Date((new Date(parseInt(this.rowData.orderPayments.ExpireDate.substr(6)))).getTime()-1).toLocaleString()),
      payTime:   (this.rowData.orderPayments==null? "":this.rowData.orderPayments.PayTime==null?"":(new Date(parseInt(this.rowData.orderPayments.PayTime.substr(6)))).toLocaleString()),
    }
  }, 
   mounted() {
    console.log(`rowData`, this.rowData);
   }
  
}
</script>

